import styled from "@emotion/styled";

import { textBody } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";

const dataTestid = "cell/empty-cell";

/** Props for {@link EmptyCellPlaceholder} */
export interface EmptyCellPlaceholderProps {
	/** Text to show when empty */
	children?: React.ReactNode;
}
/** Generic unfocused renderer */
export function EmptyCellPlaceholder(props: EmptyCellPlaceholderProps) {
	const { children } = props;

	return (
		<Empty className="placeholder" data-testid={dataTestid}>
			{children}
		</Empty>
	);
}

export const Empty = styled.span`
	color: ${theme.palette.grey[40]};
	visibility: hidden;
	${textBody}
`;
