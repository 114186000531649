import * as dateFns from "date-fns";
import * as locales from "date-fns/locale";
import { TFunction } from "next-i18next";

import { YearsParams } from "./time-years";

/** Note: we renamed the file from relative to time-relative, because of some unknown compilation issue */

/** Parameters for {@link relative} */
export interface RelativeParams
	extends Pick<YearsParams, "date" | "reference"> {
	/**
	 * Locale to format the duration
	 *
	 * @default fr-ch locale
	 */
	locale?: dateFns.Locale;
}

/**
 * Calculates and returns the difference in time between 2 dates.
 *
 * It does not manage future difference.
 *
 * Specification from: https://apptitudestudio.atlassian.net/browse/FG-16
 *
 * @param t translate function
 * @param params the get the relative date difference
 * @returns a relative time difference in words
 */
export function relative(t: TFunction, params: RelativeParams) {
	// Note: `formatRelative` of `date-fns` does sadly not correspond the specification of the project :(

	const { date, locale = locales.frCH, reference = new Date() } = params;

	const minutes = dateFns.differenceInMinutes(reference, date);
	if (minutes < 1) {
		return t("common.date.time-diff.now");
	}
	if (minutes < 60) {
		return t("common.date.time-diff.minutes-ago", { minutes });
	}

	const time = dateFns.format(date, t("common.format.time-short"));
	if (dateFns.isSameDay(date, reference)) {
		return t("common.date.time-diff.today-at", { time });
	}
	if (dateFns.isSameDay(date, dateFns.addDays(reference, -1))) {
		return t("common.date.time-diff.yesterday-at", { time });
	}

	const format = dateFns.isSameYear(date, reference)
		? t("common.format.time-diff.year-current")
		: t("common.format.time-diff.once-upon-a-time");
	return dateFns.format(date, format, { locale });
}
