import styled from "@emotion/styled";

import { Layout } from "../../../ui-layout";
import { theme } from "../../../ui-layout/styles/theme";

/** Style-props for {@link Lined} */
export interface FormCellInnerWrapperProps extends Layout.LinedProps {
	/** Set to `true` to display the disabled state */
	disabled?: boolean;
	/** Set to `true` to display the error state */
	error?: boolean;
}

/**
 * Use this cell inner wrapper when you need to display an unfocused cell with error / disabled UI
 *
 * @param props To create the node
 * @param props.error Set to `true` to display the error state
 * @returns The node
 */
export const FormCellInnerWrapper = styled(
	Layout.Lined,
)<FormCellInnerWrapperProps>`
	border: 1px solid
		${({ error }) =>
			error ? theme.palette.transactional.danger : "transparent"};
	height: 100%;
	justify-content: space-between;
	padding: 0 9px 0 13px;

	&:hover {
		border: 1px solid
			${({ error }) =>
				error
					? theme.palette.transactional.danger
					: theme.palette.grey[40]};
	}
`;
