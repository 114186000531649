import { State, StateId } from "./sort-state";

/** Params for {@link update} */
export interface UpdateParams<K extends StateId, D extends string> {
	/** Directions to apply to the sort */
	directions: readonly [D, ...D[]];
	/** Column id to sort */
	id: K;
}

/**
 * Updates the sort-state of the columns
 *
 * @param states sort states to update
 * @param params to make the update with
 * @returns updated states
 */
export function update<K extends StateId, D extends string>(
	states: ReadonlyArray<State<K, D>>,
	params: UpdateParams<K, D>,
): typeof states {
	const { directions, id: column } = params;
	if (directions.length === 0) {
		// Can do nothing without directions (forced type cast)
		return states;
	}

	const pSorting = states.findIndex(({ id }) => id === column);
	if (pSorting < 0) {
		// Not found -> add at first position
		return [{ direction: directions[0], id: column }, ...states];
	}

	// Remove at found position
	const reduced = [
		...states.slice(0, pSorting),
		...states.slice(pSorting + 1),
	];

	// current direction of the given column
	const pDirection = directions.indexOf(states[pSorting].direction);
	if (pDirection < 0) {
		// Not found in the direction (forced type cast)
		return reduced;
	}

	const nextDirection = pDirection + 1;
	return directions.length <= nextDirection
		? // Last available direction
			reduced
		: [{ direction: directions[nextDirection], id: column }, ...reduced];
}
