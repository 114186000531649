import { createElement, ReactHTML, TdHTMLAttributes, useCallback } from "react";

import {
	FocusableComponentProps,
	FocusableElementAsComponent,
	FocusableElement,
} from "../../../../utils/focusable";

/** Props for {@link TableCell} */
export interface TableCellProps
	extends FocusableComponentProps,
		Pick<TdHTMLAttributes<never>, "className" | "colSpan" | "rowSpan"> {
	/**
	 * Is the cell a header? (Use a `th` element)
	 *
	 * @default false
	 */
	asHeader?: boolean;
}

/**
 * Table cell that can focused on and show a different content
 *
 * @param props To create the node
 * @returns ReactNode
 */
export function FocusableTableCell(props: TableCellProps) {
	const {
		asHeader = false,
		className,
		colSpan,
		rowSpan,
		...focusProps
	} = props;

	const cellRole: keyof ReactHTML = asHeader ? "th" : "td";
	const as = useCallback<FocusableElementAsComponent>(
		props =>
			createElement(cellRole, {
				...props,
				className,
				colSpan,
				"data-testid": `focusable/${cellRole}`,
				rowSpan,
			}),
		[cellRole, className, colSpan, rowSpan],
	);

	return FocusableElement({ ...focusProps, as });
}
