import styled from "@emotion/styled";

import { textBodyBold } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";
import { Icon } from "../Icons/Icon";
import { TooltipGeneric } from "../tooltips";

/** Props for {@link LabelCell} */
interface LabelCellProps {
	/** The children to render */
	children: React.ReactNode;
	/** The class name */
	className?: string;
	/** Is the cell required? */
	required?: boolean;
	/** Tooltip content */
	tooltip?: string;
}

/** Component to display a table-form label as a cell */
export function LabelCell(props: LabelCellProps) {
	const { children, className, required, tooltip } = props;

	return (
		<td className={className} data-testid="cell/label">
			{children}
			{required && <Required>&nbsp;*</Required>}
			{tooltip && (
				<TooltipGeneric title={tooltip}>
					<Icon color={theme.palette.grey[40]} name="questionMark" />
				</TooltipGeneric>
			)}
		</td>
	);
}

const Required = styled.span`
	color: ${theme.palette.transactional.danger};
	${textBodyBold}
`;
