import { useTranslation } from "next-i18next";

import { birthday, BirthdayParams } from "./date-birthday";

/**
 * Hook for {@link birthday}.
 * It returns the birthday function without the i18n hook.
 *
 * @returns a translate function
 */
export function useDateBirthday() {
	const { t } = useTranslation();

	return (params: BirthdayParams) => birthday(t, params);
}

/** Props for {@link DateBirthday} */
export type DateBirthdayProps = BirthdayParams;

/**
 * Component that uses {@link useDateBirthday}.
 * It does nothing more to change it to a ReactNode
 *
 * @param props for the component
 */
export function DateBirthday(props: DateBirthdayProps) {
	return useDateBirthday()(props);
}
