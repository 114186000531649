import * as locales from "date-fns/locale";

/**
 * Gets the `date-fns` locales to use for a given language
 *
 * @param _language to get the locales from
 * @returns the locales for the given language
 */
export function getDateFnsLocal(_language: string) {
	// Yeah, there's no other language (yet?)
	return locales.frCH;
}
