import { OmitKnown } from "@nna/core";
import { useTranslation } from "next-i18next";

import { getDateFnsLocal } from "../date-fns.locale";
import { relative, RelativeParams } from "./time-relative";

/** Note: we renamed the file from Relative to TimeRelative, because of some compilation/lint error depending the file system case sensitive or not */

/** Props for {@link useRelative} & {@link Relative} */
export type RelativeProps = OmitKnown<RelativeParams, "locale">;

/**
 * Hook for {@link relative}.
 * It returns the a translate function without the i18n hook.
 *
 * @returns a translate function
 */
export function useRelative() {
	const { i18n, t } = useTranslation();

	return (params: RelativeProps) =>
		relative(t, { ...params, locale: getDateFnsLocal(i18n.language) });
}

/**
 * Component that uses {@link useRelative}.
 * It does nothing more to change it to a ReactNode
 *
 * @param props for the component
 */
export function Relative(props: RelativeProps) {
	return useRelative()(props);
}
