import { styled } from "@mui/joy";
import * as joy from "@mui/joy";

export type TooltipGenericProps = joy.TooltipProps;

export const TooltipGeneric = ({
	children,
	...props
}: TooltipGenericProps): JSX.Element => (
	<joy.Tooltip data-testid="ui/tooltip" placement="top" {...props}>
		{/* The div wrapper is needed for some children where the tooltip doesn't appear otherwise (e.g. Button) */}
		<TooltipWrapper>{children}</TooltipWrapper>
	</joy.Tooltip>
);

const TooltipWrapper = styled("div")`
	display: inline-block;
`;
