import { differenceInYears } from "date-fns";
import { TFunction } from "next-i18next";

/** Date values accepted by {@link differenceInYears} */
export type DateLike = Date | number | string;

/** Parameters for {@link years} */
export interface YearsParams {
	/** The date to get the timeYears difference from */
	date: DateLike;
	/**
	 * The reference date to get the timeYears from
	 *
	 * @default now
	 */
	reference?: DateLike;
}

/**
 * Get and translate the difference in timeYears between 2 dates
 *
 * @param t the translate function
 * @param params for the age
 * @returns the age (diff)
 */
export function years(t: TFunction, params: YearsParams) {
	const { date, reference = new Date() } = params;
	const year = differenceInYears(date, reference);

	const tParams = { year: Math.abs(year) };
	return year < 0
		? t("common.date.time-diff.years", tParams)
		: t("common.date.time-diff.years-future", tParams);
}
