import { HTMLAttributes, useState } from "react";

/** Required events for a focusable element */
export type FocusableElementEvents = Required<
	Pick<HTMLAttributes<HTMLElement>, "onBlur" | "onFocus">
>;

/** Hook output of {@link useFocusableEvents} */
export interface FocusableEventsHookOutput extends FocusableElementEvents {
	/** Is the element "focused" (focused and not yet blurred) */
	focused: boolean;
}
/**
 * Hook that returns handlers for a HTML element and its focus state.
 * It is determined is the element (or a child) has a `focus` event captured.
 * It is only set a "unfocused" when the element (nor any of its children) has the focus.
 *
 * The events should be set in the same HTML element,
 * 	otherwise the behavior is undetermined.
 *
 * @param onChange when the element gain or loose the "focus"
 * @returns event handlers
 */
export function useFocusableEvents(
	onChange: (focused: boolean) => void,
): FocusableEventsHookOutput {
	// Need an internal state to avoid emitting useless changes

	// The target is the real element that is on a "focused" state
	// The focused element can become one of its child  but it does not mean that the parent was blurred
	//	example: An Input with interactive start/end decorators
	const [target, setTarget] = useState<(EventTarget & HTMLElement) | null>(
		null,
	);

	function updateTarget(toSet: typeof target) {
		setTarget(current => {
			if (current !== toSet) {
				onChange(!!toSet);
			}

			return toSet;
		});
	}

	return {
		focused: !!target,
		onBlur: ({ relatedTarget }) => {
			if (!target) {
				// Should not happen
				return;
			}

			if (target !== relatedTarget && target.contains(relatedTarget)) {
				// Do not "un-focus" if the new "focused element" is
				//	a child of the handler of this event
				return;
			}

			updateTarget(null);
		},
		onFocus: ({ currentTarget }) => {
			if (target) {
				return;
			}

			updateTarget(currentTarget);
		},
	};
}
