import { useTranslation } from "next-i18next";

import { years, YearsParams } from "./time-years";

/** Note: we renamed the file from Years to TimeYears, because of some compilation/lint error depending the file system case sensitive or not */

/**
 * Hook for {@link years}.
 * It returns the a translate function without the i18n hook.
 *
 * @returns a translate function
 */
export function useYears() {
	const { t } = useTranslation();

	return (params: YearsParams) => years(t, params);
}

/** Props for {@link Years} */
export type YearsProps = YearsParams;

/**
 * Component that uses {@link useYears}.
 * It does nothing more to change it to a ReactNode
 *
 * @param props for the component
 */
export function Years(props: YearsProps) {
	return useYears()(props);
}
