import { ChangeEvent } from "react";

/**
 * The function in {@link transformChangeEvent} to change the value of the event
 *
 * @template I the input type (generally string from the HTML input)
 * @template O the wanted output type
 */
export type TransformChangeEventFunction<I, O> = (
	value: I,
	event: ChangeEvent<HTMLInputElement>,
) => O;
/** Options for {@link transformChangeEvent} */
export interface TransformChangeEventOptions {
	/** The name to set in the event target (if override or can not get from the default event) */
	name?: string;
}
/**
 * Transforms the value of a {@link ChangeEvent},
 * 	and still keep the event usable on `onChange` event handlers.
 *
 * @param event to transform
 * @param transform the value from the event source to the wanted one
 * @param options when transforming the event
 * @returns event with the value transformed to ISO string
 */
export function transformChangeEvent<I, O>(
	event: ChangeEvent<HTMLInputElement>,
	transform: TransformChangeEventFunction<I, O>,
	options: TransformChangeEventOptions = {},
) {
	return {
		...event,
		target: {
			...event.target,
			// `name` is a "dynamic getter", so the link is lost with the spread
			name: event.target.name ?? options?.name,
			value: transform(event.target.value as I, event) as never,
		},
	};
}
