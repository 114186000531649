import styled from "@emotion/styled";
import * as joy from "@mui/joy";
import Link, { LinkProps } from "next/link";

import { filterProps } from "../../../../services/styled/filterProps";
import { Spinner } from "../../../ui-atoms/components/Spinner";
import { textBody } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";

/** The different button variants (controls the color schemes) */
export enum ButtonVariants {
	DANGER_DISCRETE = "DANGER_DISCRETE",
	PRIMARY = "PRIMARY",
	SECONDARY = "SECONDARY",
	TERTIARY = "TERTIARY",
	TEXT = "TEXT",
}

/** The different button sizes */
export enum ButtonSizes {
	BIG = "BIG",
	SMALL = "SMALL",
	STRETCH = "STRETCH",
}

/** Button props */
export interface ButtonProps
	extends Pick<
		joy.ButtonProps,
		"className" | "disabled" | "endDecorator" | "onClick" | "startDecorator"
	> {
	/** Url to navigate to when we click the button (use this whenever are use a button to go somewhere, instead of onClick) */
	href?: LinkProps["href"];
	/** If `true`, the button will be disabled, with a spinner icon */
	isLoading?: boolean;
	/** The text displayed inside the button */
	label?: string;
	/** The button size (default: SMALL) */
	size?: ButtonSizes;
	/** The button type (default: button) */
	type?: "button" | "submit";
	/** The button color schema variant (default: PRIMARY) */
	variant?: ButtonVariants;
}

/**
 * Button component
 *
 * @param props Props to generate the component
 */
export const Button = (props: ButtonProps): JSX.Element => {
	const { href, ...btnProps } = props;
	if (href) {
		return (
			<Link href={href} passHref>
				<Button {...btnProps} />
			</Link>
		);
	}

	const {
		disabled,
		isLoading,
		label,
		size = ButtonSizes.SMALL,
		startDecorator,
		type = "button",
		variant = ButtonVariants.PRIMARY,
		...rest
	} = btnProps;

	return (
		<ButtonStyled
			$buttonSize={size}
			$buttonVariant={variant}
			data-testid="ui/button"
			disabled={isLoading || disabled}
			startDecorator={isLoading ? <Spinner /> : startDecorator}
			type={type}
			{...rest}
		>
			{label}
		</ButtonStyled>
	);
};

interface VariantStylesProps {
	background: string;
	backgroundDisabled: string;
	backgroundHover: string;
	borderColor: string;
	borderColorDisabled: string;
	textColor: string;
	textColorDisabled: string;
	textColorHover: string;
}
const variantStyles = {
	[ButtonVariants.PRIMARY]: {
		background: theme.palette.blue.primary,
		backgroundDisabled: theme.palette.grey[45],
		backgroundHover: theme.palette.grey[80],
		borderColor: "transparent",
		borderColorDisabled: "transparent",
		textColor: theme.palette.white.primary,
		textColorDisabled: theme.palette.white.primary,
		textColorHover: theme.palette.white.primary,
	},
	[ButtonVariants.SECONDARY]: {
		background: theme.palette.grey[30],
		backgroundDisabled: theme.palette.grey[20],
		backgroundHover: theme.palette.grey[45],
		borderColor: "transparent",
		borderColorDisabled: "transparent",
		textColor: theme.palette.blue.primary,
		textColorDisabled: theme.palette.grey[40],
		textColorHover: theme.palette.blue.primary,
	},
	[ButtonVariants.TERTIARY]: {
		background: theme.palette.white.primary,
		backgroundDisabled: theme.palette.white.primary,
		backgroundHover: theme.palette.grey[40],
		borderColor: theme.palette.grey[40],
		borderColorDisabled: theme.palette.grey[40],
		textColor: theme.palette.blue.primary,
		textColorDisabled: theme.palette.grey[40],
		textColorHover: theme.palette.blue.primary,
	},
	[ButtonVariants.TEXT]: {
		background: "none",
		backgroundDisabled: "none",
		backgroundHover: "none",
		borderColor: "transparent",
		borderColorDisabled: "transparent",
		textColor: theme.palette.blue.primary,
		textColorDisabled: theme.palette.grey[45],
		textColorHover: theme.palette.grey[80],
	},
	[ButtonVariants.DANGER_DISCRETE]: {
		background: "none",
		backgroundDisabled: "none",
		backgroundHover: theme.palette.transactional.danger,
		borderColor: theme.palette.transactional.danger,
		borderColorDisabled: theme.palette.grey[40],
		textColor: theme.palette.transactional.danger,
		textColorDisabled: theme.palette.grey[40],
		textColorHover: theme.palette.white.primary,
	},
} as const satisfies Record<ButtonVariants, VariantStylesProps>;

const ButtonStyled = styled(joy.Button, filterProps)<{
	$buttonSize: ButtonSizes;
	$buttonVariant: ButtonVariants;
}>`
	align-self: ${({ $buttonSize }) =>
		$buttonSize === ButtonSizes.STRETCH ? "stretch" : "auto"};
	background: ${({ $buttonVariant }) =>
		variantStyles[$buttonVariant].background};
	border: 1px solid
		${({ $buttonVariant }) => variantStyles[$buttonVariant].borderColor};
	border-radius: 4px;
	color: ${({ $buttonVariant }) => variantStyles[$buttonVariant].textColor};
	min-height: ${({ $buttonSize }) =>
		$buttonSize === ButtonSizes.SMALL ? "32px" : "40px"};
	padding: ${({ $buttonSize, $buttonVariant }) =>
		$buttonVariant === ButtonVariants.TEXT
			? "0"
			: $buttonSize === ButtonSizes.BIG
				? "0 24px"
				: "0 16px"};
	width: ${({ $buttonSize }) =>
		$buttonSize === ButtonSizes.STRETCH ? "100%" : "auto"};

	${({ $buttonVariant }) =>
		$buttonVariant === ButtonVariants.TEXT && "text-decoration: underline;"}

	${textBody}

	&:hover {
		background: ${({ $buttonVariant }) =>
			variantStyles[$buttonVariant].backgroundHover};
		color: ${({ $buttonVariant }) =>
			variantStyles[$buttonVariant].textColorHover};
	}

	&.${joy.buttonClasses.disabled} {
		background: ${({ $buttonVariant }) =>
			variantStyles[$buttonVariant].backgroundDisabled};
		border-color: ${({ $buttonVariant }) =>
			variantStyles[$buttonVariant].borderColorDisabled};
		color: ${({ $buttonVariant }) =>
			variantStyles[$buttonVariant].textColorDisabled};
		cursor: not-allowed;
		pointer-events: all !important;
	}
`;
