import { styled, TooltipProps } from "@mui/joy";
import { OmitKnown } from "@nna/core";

import { TooltipGeneric } from "./TooltipGeneric";
import { theme } from "../../../ui-layout/styles/theme";
import { Icon } from "../Icons/Icon";

/** Props for {@link Error} */
export type ErrorProps = OmitKnown<TooltipProps, "children">;

/** Tooltip to use when there is an error */
export function Error(props: ErrorProps) {
	return (
		<TooltipGeneric arrow data-testid="tooltips/error" {...props}>
			<IconContainer>
				<Icon
					color={theme.palette.transactional.danger}
					name="errorOutline"
				/>
			</IconContainer>
		</TooltipGeneric>
	);
}

const IconContainer = styled("span")`
	align-items: center;
	background: ${theme.palette.transactional.dangerTransparent};
	border-radius: 50%;
	display: flex;
	height: 24px;
	justify-content: center;
	width: 24px;

	svg {
		/** Because Joy sneakily adds some margins when it's used in an Input endDecorator */
		margin: 0;
	}
`;
