import { OmitKnown } from "@nna/core";
import React, { useEffect, useMemo, useState } from "react";

import { FocusableElementEvents, useFocusableEvents } from "./event.hook";

/** Required props for a {@link FocusableElement} "as" component */
export interface FocusableElementAsProps
	extends FocusableElementEvents,
		Pick<React.HTMLAttributes<HTMLElement>, "children">,
		Required<Pick<React.HTMLAttributes<HTMLElement>, "tabIndex">> {
	// TODO?
	// /* Is the element currently focused */
	// focused: boolean;
}
/** Component generator for a {@link FocusableElement} "as" component */
export type FocusableElementAsComponent = (
	props: FocusableElementAsProps,
) => React.ReactNode;

/** Props for {@link FocusableElement} */
export interface FocusableElementProps {
	/**
	 * The element to use as the "wrapper" for {@link FocusableElement}
	 *
	 * @default HTML div element
	 * @example
	 * // With custom style
	 * const MyDiv = styled.div``;
	 * <FocusableElement {...props} as={MyDiv} />
	 * @example
	 * // Another element and with other events
	 * <FocusableElement {...props} as={props => <td {...props} onClick={() => void 0} />} />
	 */
	as?: FocusableElementAsComponent | keyof React.ReactHTML;
	/** Component to show when the element does **not** have the focus ("unfocused" mode) */
	children?: React.ReactNode;
	/** Set to true if we should focus the component upon initial render */
	focusOnLoad?: boolean;
	/**
	 * Consider the element as "focused" (show the {@link whenFocused} component).
	 *
	 * Note: changing this does not emit {@link onFocusChange},
	 * 	nor call regular `.focus()` (~controlled state).
	 */
	focused?: boolean;
	/**
	 * Event when the element focus changes
	 *
	 * @see useFocusableEvents
	 */
	onFocusChange?: (focused: boolean) => void;
	/**
	 * Component to show when the element **has** the focus
	 *
	 * Hint: A `autoFocus` can be set on a child to be directly focused.
	 *
	 * @example
	 * <FocusableElement {...props} whenFocused={<input autoFocus />}/>
	 */
	whenFocused: React.ReactNode;
}
/**
 * Make a non-interactive HTML element "focusable"
 * 	and show a different content when focused.
 *
 * /!\ multi-level tabIndex not managed (yet?)
 *
 * @see useFocusableEvents for custom usage
 * @returns React node
 */
export function FocusableElement(props: FocusableElementProps) {
	const {
		as = "div",
		children,
		focusOnLoad,
		focused: focusedControlled,
		onFocusChange,
		whenFocused,
	} = props;

	const onFocus = useMemo(
		() => onFocusChange ?? (() => void 0),
		[onFocusChange],
	);
	const { focused: focusedUncontrolled, ...events } =
		useFocusableEvents(onFocus);

	const Element = useMemo<React.FC<FocusableElementAsProps>>(
		() =>
			typeof as === "string"
				? props => React.createElement(as, props)
				: as,
		[as],
	);

	// TODO: manage multiple tabIndex (watch for "reverse-tab" and children with `autoFocus`)
	const tabIndex = 0;
	const [focused, setFocused] = useState(false);

	useEffect(() => {
		// Update from (un)controlled state
		setFocused(
			focusedControlled === undefined
				? focusedUncontrolled
				: focusedControlled,
		);
	}, [focusedUncontrolled, focusedControlled]);

	useEffect(() => {
		if (focusOnLoad) {
			// Set initial focused, does not work if set on the `useState`:
			//	It is needed that the really first render does not shown the element and then to be added to the DOM after this change
			//	for the `autoFocus` of the `whenFocused` to be usable
			setFocused(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps -- We only want it on the first render
	}, []);

	// `tabIndex` set to `-1` to avoid to re-focus on itself on "reverse-tab" (MAJ+tab)
	return (
		<Element {...events} tabIndex={focusedUncontrolled ? -1 : tabIndex}>
			{focused ? whenFocused : children}
		</Element>
	);
}

/**
 * Reduced props for a {@link FocusableElement} with a well defined element (`as`)
 *
 * @example
 * function MyFocusable(props: FocusableComponentProps) {
 * 	return <FocusableElement {...props} as={p => <MyElement {...p} />} />;
 * }
 */
export type FocusableComponentProps = OmitKnown<FocusableElementProps, "as">;
