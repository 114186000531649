import * as dateFns from "date-fns";
import * as locales from "date-fns/locale";
import { TFunction } from "next-i18next";

/** Parameters for {@link birthday} */
export interface BirthdayParams {
	/** The birthday date */
	birthdayDate: Date;
	/**
	 * Locale to format the duration
	 *
	 * @default fr-ch locale
	 */
	locale?: dateFns.Locale;
	/** If we need to display the age as well */
	withAge?: boolean;
}

/**
 * Format a (birthday) date, with the age displayed if needed.
 *
 * @param t translate function
 * @param params the get the relative date difference
 * @returns a relative time difference in words
 */
export function birthday(t: TFunction, params: BirthdayParams) {
	const { birthdayDate, locale = locales.frCH, withAge } = params;

	const formattedBirthday = dateFns.format(
		birthdayDate,
		t("common.format.date"),
		{ locale },
	);
	const age = dateFns.differenceInYears(new Date(), birthdayDate);

	return withAge
		? t("common.date.birthday.date-with-age", {
				age,
				date: formattedBirthday,
			})
		: t("common.date.birthday.date", {
				date: formattedBirthday,
			});
}
