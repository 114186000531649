import { ParsedUrlQuery } from "querystring";

import { State, StateId } from "./sort-state";

export type ExtractableQuery = ParsedUrlQuery;

/**
 * Transforms a sorting state data to a (router) query compatible format
 *
 * @param sorting to transform
 * @returns (router) query compatible format
 */
export function transformToQuery<
	T extends ReadonlyArray<State<StateId, string>>,
>(sorting: T): Partial<Record<T[number]["id"], T[number]["direction"]>>;
/**
 * Transforms a sorting state data to a (router) query compatible format
 *
 * @param sorting to transform
 * @param prefix to add to the keys
 * @returns (router) query compatible format
 */
export function transformToQuery<
	T extends ReadonlyArray<State<StateId, string>>,
	P extends string,
>(
	sorting: T,
	prefix: P,
): Partial<Record<`${P}${T[number]["id"]}`, T[number]["direction"]>>;
/**
 * Transforms a sorting state data to a (router) query compatible format
 *
 * @param sorting to transform
 * @param prefix to add to the keys
 * @returns (router) query compatible format
 */
export function transformToQuery<
	T extends ReadonlyArray<State<StateId, string>>,
>(sorting: T, prefix = "") {
	return Object.fromEntries(
		sorting.map(({ direction, id }) => [prefix + id, direction]),
	);
}

/** Params for {@link extractFromQuery} */
export interface ExtractFromQueryParams<K extends StateId, D extends string> {
	/** Available columns to extract */
	columns: readonly K[];
	/** Valid directions to keep */
	directions: readonly D[];
	/** Prefix of the keys to keep */
	prefix?: string;
}
/**
 * Extracts from a record (supposedly a router's query) the sorting data
 *
 * @param query to extract the data from
 * @param params for extraction
 * @returns array of sorted columns
 */
export function extractFromQuery<
	const K extends StateId,
	const D extends string,
>(query: ExtractableQuery, params: ExtractFromQueryParams<K, D>) {
	const { columns, directions, prefix = "" } = params;

	const isValidCol = (str: string): str is K =>
		columns.includes(str as never);
	const isValidDir = (str: string): str is D =>
		directions.includes(str as never);

	type Output = State<K, D>;

	return Object.entries(query)
		.map(([key, value]) => {
			if (!key.startsWith(prefix)) {
				return false;
			}

			const direction = Array.isArray(value) ? value[0] : (value ?? "");
			if (!isValidDir(direction)) {
				return false;
			}

			const id = key.slice(prefix.length);
			if (!isValidCol(id)) {
				return false;
			}

			return { direction, id } satisfies Output;
		})
		.filter((out): out is Output => out !== false);
}
